// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: "Vuexy",
    appLogoImage: require("@src/assets/images/logo/logo.png").default
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: "vertical", // vertical, horizontal
    contentWidth: "full", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "floating", // static , sticky , floating, hidden
      backgroundColor: "white" // BS color options [primary, success, etc]
    },
    footer: {
      type: "static" // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  api: {
    // url: "http://tcwo0o4.52.66.151.122.sslip.io/"
    // url: "http://localhost:4000/"
    url: "https://itsupport.co.in/"

  },
  messages: {
    user: {
      add_user: "User Added successfully!",
      edit_user: "User Updated successfully!"
    },
    package: {
      add_package: "Package Added successfully!",
      edit_package: "Package Updated successfully!"
    },
    category: {
      add_category: "Category Added successfully!",
      edit_category: "Category Updated successfully!"
    },
    hotel: {
      add_hotel: "Hotel Added successfully!",
      edit_hotel: "Hotel Updated successfully!"
    },
    about: {
      add_about: "About Added successfully!",
      edit_about: "About Updated successfully!"
    },
    city: {
      add_city: "City Added successfully!",
      edit_city: "City Updated successfully!"
    },
    restourant: {
      add_restourant: "Restourant Added successfully!",
      edit_restourant: "Restourant Updated successfully!"
    },
    backgrounHistory: {
      add_backgrounHistory: "Background History Added successfully!",
      edit_backgrounHistory: "Background History Updated successfully!"
    },
    location: {
      add_location: "Location Added successfully!",
      edit_location: "Location Updated successfully!"
    },
    generalInformation: {
      add_generalInformation: "General Information Added successfully!",
      edit_generalInformation: "General Information Updated successfully!"
    },
    safetyInformation: {
      add_safetyInformation: "Safety Information Added successfully!",
      edit_safetyInformation: "Safety Information Updated successfully!"
    },
    howToReach: {
      add_howToReach: "How to Reach Added successfully!",
      edit_howToReach: "How to Reach Updated successfully!"
    },
    religiousPlaces: {
      add_religiousPlaces: "Religious Places Added successfully!",
      edit_religiousPlaces: "Religious Places Updated successfully!"
    },
    sightseeing: {
      add_sightseeing: "Sightseeing Added successfully!",
      edit_sightseeing: "Sightseeing Updated successfully!"
    },
    houseboats: {
      add_houseboats: "Houseboats Added successfully!",
      edit_houseboats: "Houseboats Updated successfully!"
    },
    hospitals: {
      add_hospitals: "Hospitals Added successfully!",
      edit_hospitals: "Hospitals Updated successfully!"
    },
    pharmacies: {
      add_pharmacies: "Pharmacies Added successfully!",
      edit_pharmacies: "Pharmacies Updated successfully!"
    },
    shopingPlaces: {
      add_shopingPlaces: "Shoping Places Added successfully!",
      edit_shopingPlaces: "Shoping Places Updated successfully!"
    },
    colagesandschools: {
      add_colagesandschools: "Colages and Schools Added successfully!",
      edit_colagesandschools: "Colages and Schools Updated successfully!"
    },
    thingstobenoted: {
      add_thingstobenoted: "Things to be noted Added successfully!",
      edit_thingstobenoted: "Things to be noted Updated successfully!"
    },
    thingsToDo: {
      add_thingsToDo: "Things to do  Added successfully!",
      edit_thingsToDo: "Things to do  Updated successfully!"
    },
    slider: {
      add_slider: "Slider Added successfully!",
      edit_slider: "Slider Updated successfully!"
    },
    herosection: {
      add_herosection: "Herosection Added successfully!",
      edit_herosection: "Herosection Updated successfully!"
    },
    howitworks: {
      add_howitworks: "How it works Added successfully!",
      edit_howitworks: "How it works Updated successfully!"
    },
    faqs: {
      add_faqs: "Faqs Added successfully!",
      edit_faqs: "Faqs Updated successfully!"
    },
    testimonials: {
      add_testimonials: "Testimonials Added successfully!",
      edit_testimonials: "Testimonials Updated successfully!"
    },
    whykashmironline: {
      add_whykashmironline: "Why Kashmir Online Added successfully!",
      edit_whykashmironline: "Why Kashmir Online Updated successfully!"
    },
    contactdetail: {
      add_contactdetail: "Contact detail Added successfully!",
      edit_contactdetail: "Contact detail Updated successfully!"
    },
    tags: {
      add_tags: "Tags Added successfully!",
      edit_tags: "Tags Updated successfully!"
    },
    car: {
      add_car: "Car Added successfully!",
      edit_car: "Car Updated successfully!"
    },
    bookingpackage: {
      add_bookingpackage: "Booking package Added successfully!",
      edit_bookingpackage: "Booking package Updated successfully!"
    },
    section: {
      add_hideShow: "Section hide/Show Added successfully!",
      edit_hideShow: "Section hide/Show Updated successfully!"
    },
    policies: {
      add_policies: "Policies Added successfully!",
      edit_policies: "Policies Updated successfully!"
    },
    youtube: {
      add_youtube: "Youtube Link Added successfully!",
      edit_youtube: "Youtube Link Updated successfully!"
    },
    profile: {
      add_profile: "User Profile Added successfully!",
      edit_profile: "User Profile Updated successfully!"
    },
    culture: {
      add_culture: "Culture Added successfully!",
      edit_culture: "Culture Updated successfully!"
    },
    products: {
      add_products: "Product Added successfully!",
      edit_products: "Product Updated successfully!"
    },
  }
}

export default themeConfig
